import React, { useContext, useMemo } from "react";
import { Configure, InstantSearch } from "react-instantsearch-dom";

import { JOB_APPLICATION_MATCH_STATUS, USER_STATUS } from "lookup";
import { MatchContext, SearchContext } from "context/providers";

import ResultsContainer from "./Results";
import { customSearchClient } from "utils/helpers/search";

const ALGOLIA_FILTERS = `status:${USER_STATUS.ACTIVE}`;

const SearchContainer = ({ children }) => {
  const {
    searchState,
    setSearchState,
    hideSkipped,
    hideRejected,
    hideMatchedAndApplied,
  } = useContext(SearchContext);
  const { matches } = useContext(MatchContext);

  const filteredObjectIDs = useMemo(() => {
    const skippedMatchesUserIDs = matches
      .filter((match) => match.status === JOB_APPLICATION_MATCH_STATUS.SKIPPED)
      .map((match) => match.userId);

    const rejectedMatchesUserIDs = matches
      .filter(
        (match) =>
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER ||
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER ||
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER
      )
      .map((match) => match.userId);

    const matchedAppliedMatchesUserIDs = matches
      .filter(
        (match) =>
          match.status === JOB_APPLICATION_MATCH_STATUS.MATCHED ||
          match.status === JOB_APPLICATION_MATCH_STATUS.APPLIED
      )
      .map((match) => match.userId);

    const filt = [];
    if (hideSkipped) filt.push(...skippedMatchesUserIDs);
    if (hideRejected) filt.push(...rejectedMatchesUserIDs);
    if (hideMatchedAndApplied) filt.push(...matchedAppliedMatchesUserIDs);

    return filt;
  }, [matches, hideSkipped, hideRejected, hideMatchedAndApplied]);

  const geoLocation = {};

  if (
    searchState.locationFilter?.aroundLatLng &&
    searchState.locationFilter?.aroundRadius &&
    searchState.locationFilter?.cityName
  ) {
    geoLocation.aroundLatLng = searchState.locationFilter?.aroundLatLng;

    geoLocation.aroundRadius = Math.round(
      searchState.locationFilter.aroundRadius * 1.60934 * 1000
    );
  }

  return (
    <InstantSearch
      searchClient={customSearchClient}
      indexName={process.env.REACT_APP_INDEX_NAME}
      onSearchStateChange={setSearchState}
      searchState={searchState}
    >
      <ResultsContainer>{children}</ResultsContainer>

      <Configure
        filters={`${ALGOLIA_FILTERS}${
          !searchState.locationFilter?.aroundRadius
            ? searchState.locationFilterStr
            : ""
        }${searchState.skillsStrFilter}${searchState.placementFilterStr}`}
        facets={["objectID"]}
        facetsExcludes={{
          objectID: filteredObjectIDs,
        }}
        restrictSearchableAttributes={
          searchState.searchByAttributes?.attributes || []
        }
        {...geoLocation}
      />
    </InstantSearch>
  );
};

export default SearchContainer;
