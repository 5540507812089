import React, { useContext, useEffect } from "react";

import { JobsContext, SearchContext } from "context/providers";

import Applications from "components/Applications";

const UserSearch = ({ signOut }) => {
  const { jobOpp, clearJob } = useContext(JobsContext);

  const { clearSearchState } = useContext(SearchContext);

  useEffect(() => {
    if (jobOpp?.id) {
      clearJob();
    }

    return () => {
      clearSearchState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!jobOpp?.id) {
      // InstantSearch may still setting state from the unmount of job page
      setTimeout(() => {
        clearSearchState();
      }, 150);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOpp?.id]);

  return <Applications signOut={signOut} />;
};

export default UserSearch;
