import dayjs from "dayjs";

export const customComparator = (a, b) => {
  if (a.endDate === null && b.endDate === null)
    return dayjs(b.startDate).diff(a.startDate);
  else if (a.endDate === null) return -1;
  else if (b.endDate === null) return 1;
  else {
    const endDateComparison = dayjs(b.endDate).diff(a.endDate);
    return endDateComparison === 0
      ? dayjs(b.startDate).diff(a.startDate)
      : endDateComparison;
  }
};
