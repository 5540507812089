import classNames from "classnames";
import { SearchContext } from "context/providers";
import {
  ENGAGEMENT_STATUS_RANGE_DATES_ITEMS,
  PREVIOUS_ENGAGEMENT_RANGE_DATES_ITEMS,
} from "lookup";
import React, { useContext } from "react";
import { connectNumericMenu, Panel } from "react-instantsearch-dom";

const PLACEMENT_FILTERS = {
  notHasActivePlacements: " AND (NOT hasActivePlacements:true)",
};

const FREE_CURRENTLY_NOT_ENGAGED = "Free (Currently Not Engaged)";

const CustomNumericMenu = ({ items, handleCheck, onChange }) => {
  return (
    <ul className="ais-NumericMenu-list">
      {items.map((item) => (
        <li className={classNames("ais-NumericMenu-item")} key={item.label}>
          <label className="ais-NumericMenu-label">
            <input
              className="ais-NumericMenu-radio flex-none"
              type="radio"
              name={item.attribute}
              checked={handleCheck(item)}
              onChange={(e) => onChange(e, item)}
            />
            <span className="ais-NumericMenu-labelText overflow-hidden">
              {item.label}
            </span>
          </label>
        </li>
      ))}
    </ul>
  );
};

const CustomEngagementsStatusRefinement = ({ items, refine, attribute }) => {
  const { searchState, setSearchState } = useContext(SearchContext);

  const handleCheck = (item) => {
    if (
      item.label === FREE_CURRENTLY_NOT_ENGAGED &&
      searchState.placementFilterStr?.startsWith(
        PLACEMENT_FILTERS.notHasActivePlacements
      )
    ) {
      return true;
    }

    if (item.label === "All" && searchState.placementFilterStr) {
      return false;
    }

    return item.isRefined;
  };

  const handleDefaultRefinement = (item) => {
    if (searchState.placementFilterStr) {
      const finalState = {
        ...searchState,
        placementFilterStr: "",
        multiRange: {
          ...searchState.multiRange,
          [attribute]: item.value,
        },
      };
      setSearchState(finalState);
    } else {
      refine(item.value);
    }
  };

  const engagementStatusOnChange = (event, item) => {
    event.preventDefault();
    if (item.label === FREE_CURRENTLY_NOT_ENGAGED) {
      let finalState = {};
      finalState = {
        ...searchState,
        placementFilterStr: PLACEMENT_FILTERS.notHasActivePlacements,
        multiRange: {
          ...searchState.multiRange,
          [attribute]: "",
        },
      };

      setSearchState(finalState);
    } else {
      handleDefaultRefinement(item);
    }
  };

  return (
    <div className="ais-NumericMenu">
      <Panel header="Engagement Status">
        <CustomNumericMenu
          items={items}
          handleCheck={handleCheck}
          onChange={engagementStatusOnChange}
        />
      </Panel>
    </div>
  );
};

const EngagementsStatusRefinement = connectNumericMenu(
  CustomEngagementsStatusRefinement
);

const CustomPreviousEngagementsRefinement = ({ items, refine }) => {
  return (
    <Panel header="PREVIOUS ENGAGEMENTS">
      <ul className="ais-RefinementList-list">
        {items
          .filter((e) => e.label !== "All")
          .map((item) => (
            <li
              key={item.label}
              className={classNames("mt-1 ais-RefinementList-item", {
                "ais-RefinementList-item--selected": item.isRefined,
              })}
            >
              <label className="flex items-center text-sm whitespace-nowrap ais-RefinementList-label">
                <input
                  className="ais-RefinementList-checkbox"
                  type="checkbox"
                  checked={item.isRefined}
                  onChange={(e) => refine(item.isRefined ? "" : item.value)}
                />
                <span className="ais-NumericMenu-labelText overflow-hidden">
                  {item.label}
                </span>
              </label>
            </li>
          ))}
      </ul>
    </Panel>
  );
};

const PreviousEngagementsRefinement = connectNumericMenu(
  CustomPreviousEngagementsRefinement
);

const PlacementsRefinementWrapper = () => {
  return (
    <>
      <EngagementsStatusRefinement
        items={ENGAGEMENT_STATUS_RANGE_DATES_ITEMS}
        attribute="activePlacements.endDateTimeStamp"
      />

      <PreviousEngagementsRefinement
        attribute="previousPlacements.endDateTimeStamp"
        items={PREVIOUS_ENGAGEMENT_RANGE_DATES_ITEMS}
      />
    </>
  );
};

export default PlacementsRefinementWrapper;
