import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  getButtonClassName,
  getButtonText,
} from "components/UserCardList/helpers/userCard";

import { JOB_APPLICATION_MATCH_STATUS } from "lookup";
import { ModalContext } from "context/providers";

import SvgIcon from "components/base/SvgIcon";
import ActionButton from "./ActionButton";
import NumericCircle from "components/NumericCircle";

const DEFAULT_BUTTON_CLASS_NAME =
  "transition-all whitespace-nowrap text-sm font-bold py-2 px-3 rounded-lg inline-flex items-center";

const HitActionButtons = ({
  hit,
  isLoading,
  showActions,
  matchAction,
  jobType,
  isJobStatusActive,
  hasPrimaryMatcher,
}) => {
  const { showModal } = useContext(ModalContext);

  const isNotCalibration = !hit.associatedMatch?.isCalibration;

  const isPreShortListed =
    hit.associatedMatch?.status === JOB_APPLICATION_MATCH_STATUS.PRESHORTLISTED;

  const noCalibrationStatuses = [
    JOB_APPLICATION_MATCH_STATUS.ACCEPTED,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER,
    JOB_APPLICATION_MATCH_STATUS.SKIPPED,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER,
    JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER,
  ];

  const canBeCalibration = !noCalibrationStatuses.includes(
    hit.associatedMatch?.status
  );

  const oneOfButtonsLoading = useMemo(() => {
    return !!Object.values(isLoading).filter((el) => !!el).length;
  }, [isLoading]);

  return (
    <div>
      <div className="flex flex-wrap items-center gap-4">
        {showActions && isNotCalibration && canBeCalibration && (
          <ActionButton
            className={classNames("text-gray-600 !py-1")}
            isDisabled={oneOfButtonsLoading}
            isLoading={isLoading["isCalibration"]}
            action={() =>
              matchAction(
                !!hit.associatedMatch
                  ? {
                      isCalibration: true,
                    }
                  : {
                      status: JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
                      isCalibration: true,
                    },
                !!hit.associatedMatch
              )
            }
            type={isNotCalibration ? "calibration" : "removeCalibration"}
            iconType="calibration"
          />
        )}

        {(!hit.associatedMatch || isPreShortListed) && showActions && (
          <>
            <ActionButton
              isDisabled={
                oneOfButtonsLoading || !isJobStatusActive || !hasPrimaryMatcher
              }
              isLoading={isLoading[JOB_APPLICATION_MATCH_STATUS.MATCHED]}
              action={() => {
                if (!hit.hasActivePlacements) {
                  matchAction(
                    { status: JOB_APPLICATION_MATCH_STATUS.MATCHED },
                    isPreShortListed
                  );
                } else if (
                  window.confirm(
                    "Are you sure you want to match this user, even though they have active engagement(s) currently?"
                  )
                )
                  matchAction(
                    { status: JOB_APPLICATION_MATCH_STATUS.MATCHED },
                    isPreShortListed
                  );
              }}
              title={
                !isJobStatusActive
                  ? "This job is not active"
                  : !hasPrimaryMatcher
                  ? "This job has no primary matcher"
                  : hit.hasActivePlacements
                  ? "WARNING: User has active engagement(s)"
                  : ""
              }
              className="text-gray-600"
              type="match"
              iconType={hit.hasActivePlacements ? "warning" : "heart"}
            />

            <button
              className={classNames(
                DEFAULT_BUTTON_CLASS_NAME,
                "text-gray-600",
                getButtonClassName(
                  "shortlist",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]
                )
              )}
              disabled={oneOfButtonsLoading}
              onClick={() =>
                matchAction(
                  {
                    status: JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
                  },
                  isPreShortListed
                )
              }
            >
              <SvgIcon type="focus" className="w-[20px] h-[20px]" />

              <span className="ml-2">
                {getButtonText(
                  "shortlist",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]
                )}
              </span>
            </button>

            <button
              className={classNames(
                DEFAULT_BUTTON_CLASS_NAME,
                "text-gray-600",
                getButtonClassName(
                  "skip",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SKIPPED]
                )
              )}
              disabled={oneOfButtonsLoading}
              onClick={() =>
                matchAction(
                  { status: JOB_APPLICATION_MATCH_STATUS.SKIPPED },
                  isPreShortListed
                )
              }
            >
              <SvgIcon type="skip" className="w-[20px] h-[20px] " />

              <span className="ml-2">
                {getButtonText(
                  "skip",
                  isLoading[JOB_APPLICATION_MATCH_STATUS.SKIPPED]
                )}
              </span>
            </button>
          </>
        )}

        <button
          className={classNames(
            DEFAULT_BUTTON_CLASS_NAME,
            "text-gray-600",
            getButtonClassName("note")
          )}
          onClick={() =>
            showModal({
              type: "note",
              title: `User notes: ${hit.username || hit.identity_username}`,
              userId: hit.id,
            })
          }
        >
          <SvgIcon type="note" className="w-[15px] h-[15px] " />

          <p className="ml-2">
            Notes {!!hit.notesCount && <NumericCircle value={hit.notesCount} />}
          </p>
        </button>
      </div>
    </div>
  );
};

HitActionButtons.propTypes = {
  hit: PropTypes.object,
  isLoading: PropTypes.object,
  showActions: PropTypes.bool,
  matchAction: PropTypes.func,
};

HitActionButtons.defaultProps = {
  hit: {},
  showActions: false,
  isLoading: {
    [JOB_APPLICATION_MATCH_STATUS.MATCHED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SKIPPED]: false,
  },
  matchAction: () => {},
};

export default HitActionButtons;
