export const SEARCH_STATE_CHANGED = "SEARCH_STATE_CHANGED";
export const HITS_LOADED = "HITS_LOADED";
export const OPERATOR_UPDATED = "OPERATOR_UPDATED";
export const HIDE_SKIPPED_CHANGED = "HIDE_SKIPPED_CHANGED";
export const HITS_MATCH_ANALYSIS_CHANGED = "HITS_MATCH_ANALYSIS_CHANGED";
export const HITS_MATCH_ANALYSIS_REMOVED = "HITS_MATCH_ANALYSIS_REMOVED";
export const HITS_MATCH_ANALYSIS_CLEARED = "HITS_MATCH_ANALYSIS_CLEARED";
export const HIDE_REJECTED_CHANGED = "HIDE_REJECTED_CHANGED";
export const HIDE_MATCHED_CHANGED = "HIDE_MATCHED_CHANGED";
export const GEOGRAPHICAL_REGION_CHANGED = "GEOGRAPHICAL_REGION_CHANGED";
export const LOCATION_FILTER_CHANGED = "LOCATION_FILTER_CHANGED";
export const COUNTRY_REFINEMENT_CHANGED = "COUNTRY_REFINEMENT_CHANGED";
export const DISJUNCTIVE_FACETS_CHANGED = "DISJUNCTIVE_FACETS_CHANGED";
