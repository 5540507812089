import React, { useState } from "react";
import PropTypes from "prop-types";

import api from "apiSingleton";
import Modal from "components/Modals";
import TextArea from "components/base/TextArea";
import ActionButton from "components/UserCardList/components/UserCardResult/molecules/ActionButton";
import { useContext } from "react";
import { AlertContext, JobsContext, MatchContext } from "context/providers";
import { JOB_APPLICATION_MATCH_STATUS } from "lookup";
import HelpText from "components/HelpText";
import SvgButton from "components/base/SvgButton";
import classNames from "classnames";

const MatchModal = ({
  onClose,
  applicationId,
  jobOpportunityId,
  userId,
  userIsUnclaimed,
  freelancerPitchPrefill,
  freelancerPitch,
  isUpdate,
  status,
  hitStatus,
  args,
}) => {
  const { addGraphQLAlert } = useContext(AlertContext);

  const [objectInputField, setObjectInputField] = useState({
    value: freelancerPitchPrefill || "",
    successMessage: "",
    errorMessage: "",
  });

  const [matchSummaryForCustomer, setMatchSummaryForCustomer] = useState(
    args?.customerSummary ?? args?.matchAnalysis?.customerSummary ?? ""
  );
  const [internalMatchAnalysis, setInternalMatchAnalysis] = useState({
    score: args?.analysis?.score ?? args?.matchAnalysis?.analysis?.score,
    report: args?.analysis?.report ?? args?.matchAnalysis?.analysis?.report,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [gettingMatchAnalysis, setGettingMatchAnalysis] = useState(false);
  const { jobOpp } = useContext(JobsContext);
  const { createMatch, updateMatch } = useContext(MatchContext);

  const handleOnChange = (e) => {
    setObjectInputField((prev) => ({
      ...prev,
      value: e.target.value,
      errorMessage: "",
      successMessage: "",
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    let successMessage = "";
    let errorMessage = "";

    try {
      const params = {};
      if (status) {
        params.status = status;
      }

      if (matchSummaryForCustomer.trim()) {
        params.customerSummary = matchSummaryForCustomer.trim();
      }

      if (internalMatchAnalysis.report || internalMatchAnalysis.score) {
        params.analysis = { ...internalMatchAnalysis };
      }

      if (isUpdate) {
        if (freelancerPitchPrefill !== objectInputField.value) {
          params.freelancerPitchPrefill = objectInputField.value;
          await updateMatch(applicationId, jobOpportunityId, params);
          successMessage = "update successful";

          status && onClose();
        }
      } else {
        if (objectInputField.value) {
          params.freelancerPitchPrefill = objectInputField.value;
        }

        if (userIsUnclaimed) {
          params.unclaimedWhenMatched = true;
        }

        await createMatch(applicationId, jobOpportunityId, userId, params);
        onClose();
      }
    } catch (error) {
      console.log("matchAction error: ", error);
      errorMessage = "Something went wrong";
    }
    setObjectInputField((prev) => ({
      ...prev,
      successMessage,
      errorMessage,
    }));
    setIsLoading(false);
  };

  const readOnlyInput = (title, text) => {
    return (
      <div className="flex flex-col w-full">
        <p className="text-2xl font-bold">
          {title} <span className="text-sm font-normal">{`(Read Only)`}</span>
        </p>
        <div className="w-full min-h-[100px] px-4 py-2 bg-white border-gray-400 border-2 cursor-default mt-2 whitespace-pre-line max-h-[175px] overflow-auto">
          {text}
        </div>
      </div>
    );
  };

  const generateMatchAnalysis = async () => {
    setGettingMatchAnalysis(true);
    try {
      const { data } = await api.match.generateMatchAnalysis({
        jobOpportunityId,
        userId,
        noCache: true,
      });

      setMatchSummaryForCustomer(data.generateMatchAnalysis.summary);
      setInternalMatchAnalysis((prev) => ({
        ...prev,
        report: data.generateMatchAnalysis.report,
        score: data.generateMatchAnalysis.score,
      }));
    } catch (err) {
      console.log("something went wrong in generateMatchAnalysis");
      addGraphQLAlert(err);
    }

    setGettingMatchAnalysis(false);
  };

  return (
    <Modal
      isVisible={applicationId && jobOpportunityId}
      title={"Application Questions"}
      onClose={onClose}
      className="max-h-[90vh] border-2 overflow-x-hidden xl:!w-7/12 md:!w-10/12 !w-[95%]"
    >
      <>
        {hitStatus !== JOB_APPLICATION_MATCH_STATUS.MATCHED &&
        hitStatus !== JOB_APPLICATION_MATCH_STATUS.INTERESTED &&
        freelancerPitch ? (
          readOnlyInput("Answered Questions:", freelancerPitch)
        ) : (
          <>
            {jobOpp.freelancerPitchPrefill &&
              readOnlyInput("Job Questions:", jobOpp.freelancerPitchPrefill)}
            <div className="flex flex-col gap-y-4">
              {hitStatus !== JOB_APPLICATION_MATCH_STATUS.MATCHED &&
              hitStatus !== JOB_APPLICATION_MATCH_STATUS.INTERESTED &&
              hitStatus !== JOB_APPLICATION_MATCH_STATUS.SHORTLISTED &&
              isUpdate ? (
                readOnlyInput("Candidate Questions:", objectInputField.value)
              ) : (
                <TextArea
                  editorLabel={"Candidate Questions:"}
                  value={objectInputField.value}
                  onChange={(e) => handleOnChange(e)}
                />
              )}
              <div className="relative">
                <div className="flex gap-x-2">
                  <p className="text-xl font-bold">Match Analysis</p>
                  <HelpText text="Get a comprehensive match analysis for this job opportunity, suitable for customer and internal use." />
                  <div className="absolute top-4 -right-4">
                    <SvgButton
                      tooltip="get analysis"
                      icon="openAIICon"
                      className={classNames(
                        "!min-w-[55px] hover:bg-transparent",
                        {
                          "animate-spin": gettingMatchAnalysis,
                          "hover:scale-110": !gettingMatchAnalysis,
                        }
                      )}
                      disabled={gettingMatchAnalysis}
                      onClick={generateMatchAnalysis}
                    />
                  </div>
                </div>

                <div className="flex gap-x-2">
                  <div className="w-full">
                    <TextArea
                      value={matchSummaryForCustomer}
                      onChange={({ target }) =>
                        setMatchSummaryForCustomer(target.value)
                      }
                      disabled={gettingMatchAnalysis}
                    />
                    <p>Summary for customer</p>
                  </div>

                  <div className="w-full">
                    <TextArea
                      value={internalMatchAnalysis.report}
                      onChange={({ target }) =>
                        setInternalMatchAnalysis((prev) => ({
                          ...prev,
                          report: target.value,
                        }))
                      }
                      disabled={gettingMatchAnalysis}
                    />
                    <p>Report</p>
                  </div>
                </div>

                <div className="flex justify-end mt-6">
                  <div className="flex flex-col">
                    {objectInputField.successMessage && (
                      <span className="text-green-600 text-sm font-bold mt-1">
                        {objectInputField.successMessage}
                      </span>
                    )}

                    {objectInputField.errorMessage && (
                      <span className="text-red-600 text-sm font-bold mt-1">
                        {objectInputField.errorMessage}
                      </span>
                    )}
                    <div className="self-end">
                      <ActionButton
                        isDisabled={
                          isLoading ||
                          freelancerPitchPrefill === objectInputField.value ||
                          gettingMatchAnalysis
                        }
                        isLoading={isLoading}
                        action={() => handleSubmit()}
                        className="text-gray-600 border-b-2"
                        title={
                          isUpdate && !status ? "Update Match" : "Create Match"
                        }
                        isUpdate={isUpdate && !status}
                        type="match"
                        iconType="heart"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </Modal>
  );
};

MatchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default MatchModal;
