import { cloneDeep, isNumber, round, sortBy, indexOf } from "lodash";

import {
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_STATUS_FORMATTED,
  JOB_APPLICATION_MATCH_SUB_STATUS_FORMATTED,
  SOCIAL_LINK_TYPES,
} from "lookup";

export const getCardClassName = (hit, showStatusColor) => {
  return {
    "!bg-yellow-50":
      !!hit.talentSource &&
      hit.associatedMatch?.status !== JOB_APPLICATION_MATCH_STATUS.MATCHED &&
      hit.associatedMatch?.status !== JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
    "opacity-75 !bg-orange-50":
      (hit.associatedMatch?.status ===
        JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER ||
        hit.associatedMatch?.status ===
          JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER ||
        hit.associatedMatch?.status === JOB_APPLICATION_MATCH_STATUS.SKIPPED ||
        hit.associatedMatch?.status ===
          JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER) &&
      showStatusColor,
    "!bg-green-50":
      (hit.associatedMatch?.status === JOB_APPLICATION_MATCH_STATUS.APPLIED ||
        hit.associatedMatch?.status === JOB_APPLICATION_MATCH_STATUS.ACCEPTED ||
        hit.associatedMatch?.status === JOB_APPLICATION_MATCH_STATUS.MATCHED) &&
      showStatusColor,
    "!bg-blue-50":
      hit.associatedMatch?.status ===
        JOB_APPLICATION_MATCH_STATUS.SHORTLISTED && showStatusColor,
  };
};

export const getButtonText = (target, isLoading, isUpdate) => {
  switch (target) {
    case "match": {
      if (!isUpdate && isLoading) {
        return "Matching...";
      }
      if (isUpdate && isLoading) {
        return "Updating...";
      }
      if (isUpdate) {
        return "Update";
      }

      return "Match";
    }

    case "calibration": {
      if (isLoading) {
        return "Updating...";
      }

      return "Calibration";
    }

    case "removeCalibration": {
      if (isLoading) {
        return "updating...";
      }

      return "Remove Calibration";
    }

    case "shortlist": {
      if (isLoading) {
        return "Shortlisting...";
      }

      return "Shortlist";
    }

    case "skip": {
      if (isLoading) {
        return "Skipping...";
      }

      return "Skip";
    }

    case "finalist": {
      if (isLoading) {
        return "updating...";
      }

      return "Finalist";
    }

    case "removeFinalist": {
      if (isLoading) {
        return "updating...";
      }

      return "Remove Finalist";
    }

    case "delete": {
      if (isLoading) {
        return "Deleting...";
      }

      return "Delete";
    }

    case "reject": {
      if (isLoading) {
        return "Rejecting...";
      }

      return "Reject";
    }

    case "apply": {
      if (isLoading) {
        return "Approving...";
      }

      return "Approve";
    }

    case "noActionJobTypeApp": {
      return "This User either does not have the corresponding Job Role for this Job Opportunity or has an inactive job role. If you wish to Match them first please use the Admin Tool to add or activate the Job Role for their profile.";
    }

    default: {
      return "";
    }
  }
};

export const getButtonClassName = (
  target,
  isLoading,
  isApplied,
  isDisabled
) => {
  switch (target) {
    case "match": {
      return {
        "hover:!fill-sky-500 hover:text-sky-500":
          !isLoading && !isApplied && !isDisabled,
        "animate-pulse": isLoading,
        "opacity-30": isApplied || isDisabled,
      };
    }

    case "removeCalibration":
    case "calibration": {
      return {
        "hover:!fill-sky-500 hover:text-sky-500": !isLoading && !isApplied,
        "animate-pulse": isLoading,
        "opacity-75": isApplied,
      };
    }

    case "shortlist": {
      return {
        "hover:!fill-sky-500 hover:text-sky-500": !isLoading && !isApplied,
        "animate-pulse": isLoading,
        "opacity-30": isApplied,
      };
    }

    case "skip": {
      return {
        "hover:!fill-sky-500 hover:text-sky-500": !isLoading && !isApplied,
        "animate-pulse": isLoading,
        "opacity-75": isApplied,
      };
    }

    case "delete": {
      return {
        "hover:!fill-red-500 hover:text-red-500": !isLoading && !isApplied,
        "animate-pulse": isLoading,
        "opacity-75": isApplied,
      };
    }

    case "finalist": {
      return {
        "hover:!fill-sky-500 hover:text-sky-500": !isLoading && !isApplied,
        "animate-pulse": isLoading,
        "opacity-75": isApplied,
      };
    }

    case "note": {
      return {
        "hover:!fill-sky-500 hover:text-sky-500": !isLoading && !isApplied,
        "animate-pulse": isLoading,
        "opacity-75": isApplied,
      };
    }

    case "reject": {
      return {
        "hover:!fill-red-500 hover:text-red-500": !isLoading && !isApplied,
        "animate-pulse": isLoading,
        "opacity-75": isApplied,
      };
    }

    case "apply": {
      return {
        "hover:text-green-400": !isLoading && !isApplied && !isDisabled,
        "animate-pulse": isLoading,
        "opacity-30": isApplied || isDisabled,
      };
    }

    default: {
      return "";
    }
  }
};

export const getSocialProfileUrl = (type, value) => {
  switch (type) {
    case SOCIAL_LINK_TYPES.TWITTER:
      return `https://twitter.com/${value}`;
    case SOCIAL_LINK_TYPES.FACEBOOK:
      return `https://www.facebook.com/${value}`;
    case SOCIAL_LINK_TYPES.INSTAGRAM:
      return `https://www.instagram.com/${value}`;
    case SOCIAL_LINK_TYPES.LINKEDIN:
      return `https://www.linkedin.com/in/${value}`;
    case SOCIAL_LINK_TYPES.STACKOVERFLOW:
      return value;
    case SOCIAL_LINK_TYPES.HASHNODE:
      return `https://hashnode.com/@${value}`;
    case SOCIAL_LINK_TYPES.GITHUB:
      return `https://github.com/${value}`;
    case SOCIAL_LINK_TYPES.PORTFOLIO:
      return value;
    default:
      break;
  }
};

export const getIconForSocialType = (type) => {
  switch (type) {
    case SOCIAL_LINK_TYPES.TWITTER:
      return "twitter";
    case SOCIAL_LINK_TYPES.INSTAGRAM:
      return "instagram";
    case SOCIAL_LINK_TYPES.LINKEDIN:
      return "linkedin";
    case SOCIAL_LINK_TYPES.FACEBOOK:
      return "facebook";
    case SOCIAL_LINK_TYPES.STACKOVERFLOW:
      return "stackOverflow";
    case SOCIAL_LINK_TYPES.HASHNODE:
      return "hash";
    case SOCIAL_LINK_TYPES.GITHUB:
      return "github";
    case SOCIAL_LINK_TYPES.PORTFOLIO:
      return "website";
    default:
      break;
  }
};

export const prepareLocation = (location) => {
  let value = "";

  if (location) {
    if (location.cityName) {
      value = `${location.cityName}`;
    }

    if (location.stateName) {
      value = `${value.length > 0 ? `${value},` : `${value}`} ${
        location.stateName
      }`;
    }

    if (location.countryName) {
      value = `${value.length > 0 ? `${value},` : `${value}`} ${
        location.countryName
      }`;
    }
  }

  return value;
};

export const prepareHourlyRate = (rateObject) => {
  if (!rateObject?.value) {
    return "Not Specified";
  }

  const CURRENCY_SCHEMA = {
    USD: "$",
  };

  const output = `${CURRENCY_SCHEMA[rateObject?.currency] || ""}${
    rateObject?.value
  }`;

  return output;
};

export const prepareAvailability = (availability) => {
  const AVAILABILITY_SCHEMA = {
    FULLTIME: "Full Time",
    OPENTOOFFERS: "Open To Offers",
    FULLTIMEIMMEDIATELY: "Full Time Immediately",
    PARTTIME: "Part Time",
  };

  if (!availability || !AVAILABILITY_SCHEMA[availability]) {
    return "Not Specified";
  }

  return AVAILABILITY_SCHEMA[availability];
};

export const formatTalentSource = (user) => {
  let markup = user.additionalMarkup;

  if (!user?.talentSource) {
    return null;
  }

  if (markup && isNumber(markup)) {
    markup = ` (${round(markup, 2).toFixed(2)})`;
  }

  return user.talentSource + markup;
};

const isRejectedStatus = (status) => {
  const rejectedStatuses = new Set([
    JOB_APPLICATION_MATCH_STATUS_FORMATTED.REJECTEDBYCUSTOMER,
    JOB_APPLICATION_MATCH_STATUS_FORMATTED.REJECTEDBYMATCHER,
    JOB_APPLICATION_MATCH_STATUS_FORMATTED.REJECTEDBYMEMBER,
  ]);

  return rejectedStatuses.has(status);
};

export const createFilter = (matches, filterKey) => {
  const filter = {};
  const clonedMatches = cloneDeep(matches).sort((a, b) =>
    a.match[filterKey] < b.match[filterKey] ? -1 : 1
  );

  clonedMatches.forEach((app) => {
    if (app.match[filterKey]) {
      filter[app.match[filterKey]] = {
        label:
          JOB_APPLICATION_MATCH_STATUS_FORMATTED[app.match[filterKey]] ||
          JOB_APPLICATION_MATCH_SUB_STATUS_FORMATTED[app.match[filterKey]],
        value:
          filterKey === "status" &&
          !isRejectedStatus(
            JOB_APPLICATION_MATCH_STATUS_FORMATTED[app.match[filterKey]]
          ),
      };
    }
  });

  return filter;
};

const filterOutMatchingJobSkills = (hitSkills, jobSkills) => {
  return hitSkills.filter(
    (hitSkill) => !jobSkills.some((jobSkill) => jobSkill.name === hitSkill.name)
  );
};

export const mapSkills = (jobSkills = [], hitSkills = []) => {
  const experienceOrder = ["high", "medium", "low"];

  const mappedJobSkills = jobSkills.map((jobSkill) => {
    const hitSkill = hitSkills.find(({ name }) => name === jobSkill.name);

    return {
      ...jobSkill,
      ...hitSkill,
      hitHasJobSkill: !!hitSkill,
      isJobSkill: true,
    };
  });

  const filteredHitSkills = filterOutMatchingJobSkills(
    hitSkills,
    mappedJobSkills
  );

  const finalMappedSkills = [...mappedJobSkills, ...filteredHitSkills];

  // sort skills required job skills, experienceOrder
  const sortedSkills = sortBy(finalMappedSkills, [
    "isJobSkill",
    ({ hitHasJobSkill }) => {
      return !hitHasJobSkill;
    },
    (item) => {
      const experienceIndex = indexOf(experienceOrder, item.experience);
      return experienceIndex === -1 ? experienceOrder.length : experienceIndex;
    },
    "name",
  ]);

  return sortedSkills;
};
