import produce from "immer";

import { contextConstants } from "context/constants";
import { set } from "lodash";

export const initialState = {
  matches: [],
  bestFit: [],
  ideal: [],
  loadingIdeal: "Loading...",
  mappedHitsMatchesBestFit: null,
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.match.MATCHES_LOADED: {
      draft.matches = payload.matches;
      break;
    }

    case contextConstants.match.MATCH_1_LEVEL_FIELD_UPDATED: {
      const index = draft.matches.findIndex(
        (match) => match.applicationId === payload.applicationId
      );
      if (index !== -1) {
        draft.matches[index][payload.fieldKey] = payload.value;
      }
      break;
    }

    case contextConstants.match.BEST_FIT_FIELD_UPDATED: {
      const index = draft.bestFit.findIndex((fit) => fit.id === payload.userId);
      if (index !== -1) {
        set(draft.bestFit[index], payload.fieldKey, payload.value);
      }
      break;
    }

    case contextConstants.match.IDEAL_CANDIDATE_FIELD_UPDATED: {
      const index = draft.ideal.findIndex((fit) => fit.id === payload.userId);
      if (index !== -1) {
        set(draft.ideal[index], payload.fieldKey, payload.value);
      }
      break;
    }

    case contextConstants.match.MATCH_USER_FIELD_UPDATED: {
      const index = draft.matches.findIndex(
        (match) => match.applicationId === payload.applicationId
      );
      if (index !== -1 && draft.matches[index]?.user) {
        draft.matches[index].user[payload.fieldKey] = payload.value;
      }
      break;
    }

    case contextConstants.match.BEST_FIT_LOADED: {
      draft.bestFit = payload.bestFit;
      break;
    }

    case contextConstants.match.IDEAL_CANDIDATES_LOADED: {
      draft.ideal = payload.ideal;

      if (Object.keys(payload).includes("loadingIdeal")) {
        draft.loadingIdeal = payload.loadingIdeal;
      }
      break;
    }

    default: {
      break;
    }
  }
});
