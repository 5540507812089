import React, { useContext, useEffect, useState } from "react";
import { CSVDownload } from "react-csv";
import dayjs from "dayjs";

import { customSearchClient, prepareQuery } from "utils/helpers/search";
import { prepareHourlyRate } from "./UserCardList/helpers/userCard";

import { JobsContext, SearchContext } from "context/providers";
import { USER_STATUS } from "lookup";
import SvgButton from "./base/SvgButton";

const prepareLocation = (location) => {
  if (location?.countryName) {
    return location.countryName;
  }

  return "";
};

const prepareAvailability = (availability) => {
  if (!availability) return "";
  return availability;
};

const prepareLangs = (langs) => {
  if (!langs || !langs?.length) {
    return "Not Specified";
  }

  const preparedArray = langs.map(
    (lang) =>
      `${lang.language} (${lang.level[0]}${lang.level.slice(1).toLowerCase()})`
  );

  return preparedArray.join(", ");
};

const prepareData = (data) => {
  return data.map((h) => ({
    username: h.username,
    given_name: h.given_name,
    family_name: h.family_name,
    profileCompletion: h.profileCompletion || 0,
    location: prepareLocation(h.location),
    availability: prepareAvailability(h?.availability),
    ratePerHour: prepareHourlyRate(h?.ratePerHour),
    knownLanguages: prepareLangs(h.knownLanguages),
    createdAt: dayjs(h.createdAt).format("MM/DD/YYYY"),
  }));
};

export default function DownloadResults({ className, data }) {
  const searchState = useContext(SearchContext);
  const { jobOpp } = useContext(JobsContext);
  const [preparingCSV, setPreparingCSV] = useState(false);
  const [exportCSV, setExportCSV] = useState(null);

  useEffect(() => {
    if (data && exportCSV) {
      setExportCSV(null);
    }
  }, [data, exportCSV]);

  const exportAsCSV = async () => {
    const filters = prepareQuery(searchState, jobOpp.jobType?.title);

    setPreparingCSV(true);

    try {
      const results = [];
      let indexing = true;
      let page = 0;
      while (indexing) {
        const data = await customSearchClient.search([
          {
            indexName: process.env.REACT_APP_INDEX_NAME,
            query: searchState.searchState?.query || "",
            params: {
              attributesToRetrieve: [
                "username",
                "given_name",
                "family_name",
                "profileCompletion",
                "location",
                "availability",
                "ratePerHour",
                "knownLanguages",
                "createdAt",
              ],
              hitsPerPage: 1000,
              ...filters,
              filters: `status:${USER_STATUS.ACTIVE}${searchState.searchState.skillsStrFilter}${searchState.searchState.locationFilterStr}${searchState.searchState.placementFilterStr}`,
              page,
            },
          },
        ]);

        if (data?.results[0]?.hits?.length === 0) {
          indexing = false;
        }

        results.push(...data.results[0].hits);
        page++;
      }

      setExportCSV(prepareData(results));

      setPreparingCSV(false);
    } catch (e) {
      console.log(e);
      alert("An error occurred preparing the data for download. Try again.");
      setExportCSV(null);
      setPreparingCSV(false);
    }
  };

  const handleExportClick = async () => {
    if (data) {
      // use provided data
      setExportCSV(prepareData(data));
    } else {
      // download data
      await exportAsCSV();
    }
  };

  return (
    <>
      <SvgButton
        icon="downloadFile"
        className={className}
        onClick={handleExportClick}
        disabled={preparingCSV}
      />
      {!preparingCSV && exportCSV && (
        <CSVDownload data={exportCSV} target="_blank" />
      )}
    </>
  );
}
