import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { contextConstants } from "context/constants";
import { contextReducers } from "context/reducers";
import { contextActions } from "context/actions";
import { JobsContext } from "./JobsProvider";
import { AuthContext } from "./AuthProvider";

const STATUSES_KEYS_LINKING = {
  SHORTLISTED: "isShortlisting",
  MATCHED: "isMatching",
  DELETED: "isDeleting",
  SKIPPED: "isSkipping",
};

export const MatchContext = createContext();

export const MatchProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { jobOpp, initJob, updateJobLocally } = useContext(JobsContext);

  const [state, dispatch] = useReducer(
    contextReducers.match.reducer,
    contextReducers.match.initialState
  );

  const updateMappedHitsMatchesBestFit = useCallback((list) => {
    dispatch({
      type: contextConstants.match.MAPPED_HITS_MATCHES_BEST_FIT_UPDATED,
      payload: { mappedHitsMatchesBestFit: list },
    });
  }, []);

  const initMatches = () => {
    const matches = contextActions.match.initMatches(jobOpp);

    dispatch({
      type: contextConstants.match.MATCHES_LOADED,
      payload: { matches },
    });
  };

  const initBestFit = async () => {
    if (process.env.REACT_APP_SEARCH_TOOL_BEST_FIT_ENABLED === "true") {
      const bestFit = await contextActions.match.initBestFit(jobOpp);

      dispatch({
        type: contextConstants.match.BEST_FIT_LOADED,
        payload: { bestFit },
      });
    }
  };

  const initIdeal = async () => {
    let ideal = [];
    let loadingIdeal = "";

    if (user.canFetchIdealCandidates) {
      try {
        ideal = await contextActions.match.initIdealCandidates(jobOpp);
      } catch (err) {
        loadingIdeal = (
          <div>
            <p>An error occurred fetching the ideal candidates for the job. </p>
            <p>Try refreshing the page.</p>
            <p>If error persists, contact Torc Support.</p>
          </div>
        );
      }
    }

    dispatch({
      type: contextConstants.match.IDEAL_CANDIDATES_LOADED,
      payload: { ideal, loadingIdeal },
    });
  };

  const updateMatchFieldLocally = (applicationId, fieldKey, value) => {
    const matches = contextActions.match.updateMatchFieldLocally(
      applicationId,
      fieldKey,
      value,
      state.matches
    );

    const updatedMatch = matches.find(
      (el) => el.applicationId === applicationId
    );

    dispatch({
      type: contextConstants.match.MATCHES_LOADED,
      payload: { matches },
    });

    return updatedMatch;
  };

  const updateMatch1LevelFieldLocally = (applicationId, fieldKey, value) => {
    dispatch({
      type: contextConstants.match.MATCH_1_LEVEL_FIELD_UPDATED,
      payload: { applicationId, fieldKey, value },
    });
  };

  const updateMatchUserFieldLocally = (applicationId, fieldKey, value) => {
    dispatch({
      type: contextConstants.match.MATCH_USER_FIELD_UPDATED,
      payload: { applicationId, fieldKey, value },
    });
  };

  const updateBestFitFieldLocally = (userId, fieldKey, value) => {
    dispatch({
      type: contextConstants.match.BEST_FIT_FIELD_UPDATED,
      payload: { userId, fieldKey, value },
    });
  };

  const updateIdealCandidateFieldLocally = (userId, fieldKey, value) => {
    dispatch({
      type: contextConstants.match.IDEAL_CANDIDATE_FIELD_UPDATED,
      payload: { userId, fieldKey, value },
    });
  };

  const updateFitFieldLocally = (id, fieldKey, value) => {
    const bestFit = contextActions.match.updateFitFieldLocally(
      id,
      fieldKey,
      value,
      state.bestFit
    );

    const updatedFit = bestFit.find((el) => el.id === id);

    dispatch({
      type: contextConstants.match.BEST_FIT_LOADED,
      payload: { bestFit },
    });

    return updatedFit;
  };

  const removeMatchLocally = (applicationId) => {
    const matches = contextActions.match.removeMatchLocally(
      applicationId,
      state.matches
    );

    dispatch({
      type: contextConstants.match.MATCHES_LOADED,
      payload: { matches },
    });
  };

  const replaceMatchLocally = (match) => {
    const matches = contextActions.match.replaceMatchLocally(
      match,
      state.matches
    );

    dispatch({
      type: contextConstants.match.MATCHES_LOADED,
      payload: { matches },
    });

    const jobOppMatches = { ...jobOpp.matches, items: matches };

    updateJobLocally(jobOppMatches, "matches");
  };

  const createMatch = async (applicationId, jobOpportunityId, userId, args) => {
    try {
      await contextActions.match.createMatch({
        applicationId,
        jobOpportunityId,
        userId,
        ...args,
      });

      await initJob(jobOpp.id);
    } catch (error) {
      console.log("callMatchAction error: ", error);
    }
  };

  const updateMatch = async (applicationId, jobOpportunityId, args) => {
    try {
      const existingMatch = state.matches.find(
        (el) => el.applicationId === applicationId
      );

      await contextActions.match.updateMatch({
        applicationId,
        jobOpportunityId,
        ...args,
      });

      const params = {
        ...existingMatch,
        ...args,
      };
      if (args.status) {
        params[STATUSES_KEYS_LINKING[args.status]] = false;
      }

      replaceMatchLocally(params);
    } catch (error) {
      console.log("callMatchAction error: ", error);
    }
  };

  const removeMatch = async (applicationId, jobOpportunityId) => {
    try {
      await contextActions.match.removeMatch(applicationId, jobOpportunityId);

      removeMatchLocally(applicationId);
    } catch (error) {
      console.log("removeMatch error: ", error);
    }
  };

  const clearMatches = () => {
    dispatch({
      type: contextConstants.match.MATCHES_LOADED,
      payload: { matches: [] },
    });

    dispatch({
      type: contextConstants.match.BEST_FIT_LOADED,
      payload: { bestFit: [] },
    });

    dispatch({
      type: contextConstants.match.IDEAL_CANDIDATES_LOADED,
      payload: { ideal: [] },
    });
  };

  const match = {
    ...state,
    initMatches,
    createMatch,
    updateMatch,
    removeMatch,
    updateMatchFieldLocally,
    updateMatch1LevelFieldLocally,
    updateMatchUserFieldLocally,
    updateFitFieldLocally,
    updateBestFitFieldLocally,
    updateIdealCandidateFieldLocally,
    removeMatchLocally,
    replaceMatchLocally,
    clearMatches,
    updateMappedHitsMatchesBestFit,
  };

  useEffect(() => {
    if (jobOpp.id) {
      initMatches();
      initBestFit();
      initIdeal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOpp]);

  return (
    <MatchContext.Provider value={match}>{children}</MatchContext.Provider>
  );
};
